.App {
  text-align: center;
}

.App-logo {
  height: 38px;
  pointer-events: none;
}



@font-face {
  font-family: 'Gilroy-Heavy';
  src: local('Gilroy-Heavy'), url(./Fonts/Gilroy-Heavy.ttf) format('opentype');
}

@font-face {
  font-family: 'Nexa-Light';
  src: local('Nexa-Light'), url(./Fonts/Nexa-Light.ttf) format('opentype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

input {border:0;outline:0;}
input:focus {outline:none!important;}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
